.order-trans {
  background-color: #ffffff;
  padding: 32px 12px 24px;
  border-radius: 12px;
  margin-bottom: 60px;
  .order-trans-content {
    border: 1px solid #cccccc;
    border-radius: 8px;
    margin-top: 18px;
  }
}

.order-trans-items {
  display: flex;
  align-items: center;
  .order-trans-items-img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-right: 1px solid #ccc;
  }
  .order-trans-items-product {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding: 3px 0 3px 14px;
    p {
      margin-bottom: 0;
      font-size: 12px;
    }
  }
}
.order-trans-buttons {
  display: flex;
}
