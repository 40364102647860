.cart-listing {
  background-color: #ffffff;
  padding: 32px 12px 24px;
  border-radius: 12px;
  margin-bottom: 60px;
}

.cart-add-location {
  cursor: pointer;
}

.cart-listing-container {
  border: 1px solid #cccccc;
  border-radius: 8px;
  margin-top: 18px;
}
.form-floating {
  select {
    background-color: #d2e9ff !important;
    color: #7b7b7b !important;
    font-size: 12px !important;
  }
}

.cart-listing-items {
  // display: flex;
  // align-items: center;
  .cart-listing-img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-right: 1px solid #ccc;
  }
  .cart-listing-product {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding: 3px 0 3px 14px;
    p {
      margin-bottom: 0;
      font-size: 12px;
    }
  }
}

.cartlisting-delete > i {
  color: rgba(255, 0, 0, 0.829);
  cursor: pointer;
  margin-right: 12px;
}

.delivery-details {
  border: 1px solid #cccccc;
  border-radius: 8px;
  margin: 18px auto 20px;
  padding: 12px 8px;
  p {
    margin-bottom: 12px;
    font-size: 12px;
  }
  .locationing {
    color: grey;
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 700;
  }
  .change-delivery {
    color: #1e90ff;
    cursor: pointer;
  }
}

@media screen and (max-width: 425px) {
  // .cart-listing-container {
  //   margin-bottom: 12px;
  // }
  .cartlisting-delete > i {
    text-align: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}
