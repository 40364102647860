.ProductDetailPage-product {
  height: auto;
  padding: 24px 0;
  background: linear-gradient(54.64deg, #ffffff -40.98%, #1e90ff 56.2%);
  display: flex;
}

.ProductDetailPage-jumbotrone {
  background-color: #ffffff;
  padding: 24px 0 0;
  border-radius: 32px;
  box-shadow: 0px 8px 10px 0px #00000033;
}
.ProductDetailPage-jumbotrone-row {
  padding: 28px 52px;
}
.ProductDetailPage-jumbotrone-info p {
  margin: 12px auto;
}
.ProductDetailPage-jumbotrone-info h5 {
  margin-bottom: 18px !important;
}

.ProductDetailPage-description {
  border: 1px solid #a3a3a3;
  border-radius: 8px;
  margin-top: 24px;
  padding: 12px 24px;
}
.ProductDetailPage-description-p {
  color: #1e90ff;
  text-decoration: underline;
  font-weight: 600;
}

@media screen and (max-width: 425px) {
  .ProductDetailPage-product {
    height: auto;
  }

  .ProductDetailPage-jumbotrone-row {
    padding: 14px;
  }
}
