.homepage-header {
  padding: 40px 0 36px;
  background-color: #ffffff;
}

.small-cards {
  padding: 42px 0 0;
}

.homepage-jumbotrone-1 {
  padding: 48px 0;
  background: linear-gradient(121.76deg, #1e90ff 33.69%, #ffffff 114.43%);
}

.homepage-new-product {
  padding: 48px 0 28px;
  background-color: #d2e9ff;
}

.homepage-delivery-commitment {
  background: linear-gradient(121.76deg, #1e90ff 33.69%, #ffffff 114.43%);
  padding: 48px 0;
}

.homepage-categories {
  background-color: #ffffff;
  padding: 48px 0 28px;
}

.homepage-new-products {
  background-color: #d2e9ff;
  padding: 48px 0 28px;
}

.homepage-main-product {
  background-color: #ffffff;
  padding: 48px 0 28px;
}

.homepage-footer-jumbotron {
  background-color: #d2e9ff;
  padding: 48px 0 28px;
}

/* At alternating screens */
@media screen and (max-width: 425px) {
  .col-md-3 {
    margin-bottom: 8px;
  }
}
