.vendor-main-header {
  padding: 40px 0 36px;
  background-color: #ffffff;
}

.main-content {
  background: linear-gradient(
    224.87deg,
    #ffffff -5%,
    #1e90ff -5%,
    #2f4f4f 104.25%
  );
  padding: 48px 0;

  .container-content {
    background-color: #ffffff;
    border-radius: 48px;
    padding: 48px 24px;
    width: 90%;
  }
  .inputHolder {
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    input {
      background-color: white !important;
    }
  }
}

.container-content-vendors {
  width: 99%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.jumbotron-area {
  background-color: #d2e9ff;
  padding: 38px 0 32px;
}
