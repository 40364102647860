.small-card-main {
  background-color: #ffffff;
  box-shadow: 0px 3px 5px 0px #00000033;
  width: 100%;
  height: 100%;
  max-height: 150px;
  padding: 32px 0;
  font-size: 26px;
  border-radius: 12px;
  .me-2 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem;
  }
  .small-card-main-text {
    font-size: 14px;
  }
}

.small-card-main:hover {
  background-color: #ffffff;
  box-shadow: 0px 8px 10px 0px #00000033;
  cursor: pointer;
  width: 100%;
  height: 100%;
  max-height: 150px;
  padding: 32px 0;
  font-size: 26px;
  border-radius: 12px;
}

@media screen and (max-width: 768px) and (min-width: 426px) {
  .small-card-main {
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0px 3px 5px 0px #00000033;
    width: 100%;
    height: 100%;
    max-height: 150px;
    padding: 32px 0;
    font-size: 18px;
    border-radius: 12px;
    .faviconz {
      font-size: 16px;
    }
    // .me-2 {
    //   margin-right: 1.2rem !important;
    //   margin-left: 1.2rem;
    // }
    .small-card-main-text {
      font-size: 12px;
      text-align: center;
      padding: 0 6px;
    }
  }
}

@media screen and (max-width: 425px) {
  .small-card-main {
    justify-content: center;
    .faviconz {
      font-size: 18px;
    }
    .small-card-main-text {
      font-size: 16px;
      text-align: center;
    }
  }
}
