h3 {
  color: #2f4f4f !important;
}
input {
  background-color: #d2e9ff !important;
  color: #7b7b7b !important;
  font-size: 12px !important;
}

hr {
  border: 1px solid #ffffff;
}

.footer {
  margin-top: 48px;
}

.top-footer {
  width: 75% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: -40px;

  .footer-subscription {
    display: flex;
    background-color: #d2e9ff;
    border-radius: 80px;
    max-width: 1270px;
    max-height: 212px;
    padding: 18px 30px 16px 30px;

    align-items: center;
    gap: 0;

    .footer-image img {
      height: auto;
      width: auto;
      // margin-left: 32px;
    }

    .top-footer-texts {
      width: 75%;
    }
  }
}

.bottom-footer {
  background-color: #1e90ff;
  color: #ffffff;

  h5 {
    color: #ffffff !important;
    font-weight: 600 !important;
  }
  .footer-bottom-main {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    padding: 62px 0 12px !important;
    align-items: baseline;

    .footer-brand img {
      height: auto;
      width: auto;
      max-width: 170px;
      max-height: 63px;
      margin-bottom: 24px;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        margin-bottom: 12px;
        font-size: 12px;
        a {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
    .footer-follow-us {
      display: flex;
      li {
        margin-right: 12px;
        font-size: 24px;
      }
    }
  }
  .footer-copywrite {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 14px;
  }
}

@media screen and (max-width: 425px) {
  .top-footer {
    .footer-subscription {
      .footer-image img {
        display: none;
      }

      .top-footer-texts {
        width: 75%;
        h3 {
          font-size: 18px;
          text-align: justify;
        }
      }
    }
  }
  // Bottom Footer
  .bottom-footer {
    background-color: #1e90ff;
    color: #ffffff;

    h5 {
      text-align: center;
      margin-top: 18px;
      margin-bottom: 26px;
    }

    .footer-bottom-main {
      .footer-brand img {
        display: flex;
        justify-content: center;
        max-width: 135px;
        margin-left: auto;
        margin-right: auto;
      }
      ul {
        li {
          text-align: center;
        }
      }
      .footer-follow-us {
        display: flex;
        justify-content: center;
        li {
          margin-right: 14px;
        }
      }
    }
  }
}
