.main-header {
  // .img-style {
  //   width: 100% !important;
  //   height: auto;
  // }
  p {
    text-align: justify;
  }

  button {
    margin-left: 0 !important;
  }
}

.justify-content-center img {
  height: 38vh;
  width: 75%;
}
