.profile-top-bar-container {
  display: grid;
  grid-template-areas: "left left right right right";
  gap: 250px;
  .profile-top-bar-Back {
    // grid-area: left;
    cursor: pointer;
    padding-left: 0;
    font-size: 27px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .profile-top-bar-brand {
    grid-area: right;
  }
}
.profile-top-bar {
  padding: 24px 0;
}
.bg-lights {
  background-color: #f5f8f9 !important;
}

@media screen and (max-width: 425px) {
  .profile-top-bar-brand {
    display: none;
  }
}

@media screen and (min-width: 426px) and (max-width: 1024px) {
  .profile-top-bar-container {
    display: grid;
    grid-template-areas: "left left right right";
    gap: 25px;
  }
}
