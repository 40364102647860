.bg-lights {
  background-color: #d9ebf9 !important;
}

.top-bar-button button {
  margin-left: 12px !important;
}

li .nav-item {
  color: #000000 !important;
}

.active {
  background: linear-gradient(330deg, #ffffff, #1e90ff, #2f4f4f) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  color: transparent !important;
}

@media screen and (max-width: 768px) {
  .navbar-collapse ul li {
    text-align: center;
  }
  .navbar-nav button {
    margin-bottom: 8px;
  }
}
