.login-holder {
  background: linear-gradient(to right, #1e90ff 50%, #d9ebf9 50%) !important;
  height: 100vh;
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center;
}

.login-content {
  width: 50%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 24px 50px 24px;
  background-color: #ffffff;
  box-shadow: 0px 8px 10px 0px #00000033;
  border-radius: 24px;

  .login-content-headers {
    h4 {
      text-align: center;
      margin-bottom: 0;
    }
    .login-img {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-top: 0;
      margin-bottom: 32px;
    }
  }

  .login-content-form {
    label {
      font-size: 14px;
    }
    input {
      background-color: #ffffff !important;
    }
    p {
      text-align: right;
      padding-top: 0;
      margin-top: 0;
    }
  }
}
.login-image {
  position: absolute;
  bottom: 0;
  right: 90px;
  width: 28%;
}
.modal-content {
  padding: 28px !important;
}

.modal-content img {
  margin-bottom: 12px;
}

.spinner-border {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50% -50%);
}

@media screen and (max-width: 425px) {
  .login-content {
    // height: 90vh;
    width: 85%;
  }
  .login-content-form {
    label {
      font-size: 6px;
      text-align: center;
    }
    input {
      background-color: #ffffff !important;
    }
    p {
      text-align: right;
      padding-top: 0;
      margin-top: 0;
      font-size: 10px;
    }
  }
  .login-footer-text {
    font-size: 12px;
  }
  .login-image {
    display: none;
  }
}
