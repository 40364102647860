.sidebar {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 24px 12px;
  p {
    font-size: 24px;
    margin-bottom: 24px;
    i {
      margin-right: 8px;
    }
    a {
      color: black;
      text-decoration: none;
    }
  }
}

.logout p {
  color: #ff3b30;
  font-weight: 700;
  text-align: center;
}

// .delete-account:hover {
//   display: hidden;
// }
