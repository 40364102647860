.imgJumbotron-main {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 12px;
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: white;
    text-align: center;
  }
}

.imgJumbotron-main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.8); /* Black overlay with 0.4 opacity */
  z-index: 1; /* Ensure the overlay is behind the text */
}

@media screen and (max-width: 425px) {
  // .paragraph1 {
  //   font-size: 18px !important;
  // }
  // .paragraph2 {
  //   font-size: 30px !important;
  // }
  // .imgJumbotron-buttons {
  //   width: 200px !important;
  // }
}

// .btn {
//   width: 271px !important;
// }
