.notification-profile {
  background-color: #ffffff;
  padding: 32px 0 24px 12px;
  border-radius: 12px;
  margin-bottom: 60px;
}

.notification-profile-content {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  .notification-profile-content-img {
    background-color: #d2e9ff;
    width: 100px;
    height: 100px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .notification-profile-content-product {
    padding: 3px 0 3px 14px;
    p {
      margin-bottom: 0;
      font-size: 12px;
    }
  }
  .notification-profile-content-time {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
