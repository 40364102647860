.contact-main-header {
  padding: 40px 0 36px;
  margin-bottom: 28px;
  background-color: #ffffff;
}

.contact-first-content {
  padding: 58px 0 72px;
  .contact-first-content-highlight {
    background-color: #ffffff;
    display: flex;
    padding: 32px 62px;
    .highlight1 {
      flex: 1;
    }
  }
}

.faviconz {
  font-size: 32px;
}

.contact-form {
  background-color: #fffefe;
  margin-top: 32px;
  padding: 60px 0 40px;
  .contact-form-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    input {
      background-color: #ffffff !important;
    }
  }
}

.style-button button {
  margin: auto 0 !important;
}

@media screen and (max-width: 768px) {
  .contact-first-content {
    .contact-first-content-highlight {
      flex-direction: column;
      padding: 18px 24px;
      .highlight1 {
        margin-bottom: 32px;
      }
    }
  }
}
