.faq-main-header {
  padding: 40px 0 36px;
  background-color: #ffffff;
}

.faq-main-content-header {
  border: 1px solid #242424;
  border-radius: 6px;
  cursor: pointer;
  padding: auto 32px;
}
.faq-main-content-header p {
  font-size: 18px;
  padding: 0 24px;
  margin: 6px auto;
}

.faq-main-content {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  margin-top: 38px;
  margin-bottom: 138px;
  padding: 38px 0;

  input {
    background-color: transparent !important;
  }
}

.faq-sub-content {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  text-align: center;
}

.faq-image {
  display: flex;
  justify-content: baseline;
  align-items: start;
  width: 30%;
  margin-top: -128px;
  margin-left: -128px;
}
