.card {
  width: 100%;
  height: 76vh !important;
  box-shadow: 0px 2px 2px 1px #0000001f;
  transition: box-shadow 0.3s ease-in-out;
  margin-bottom: 18px;
  cursor: pointer;
  img {
    width: 100%;
    height: 50%;
    // padding-top: 20px;
  }
  .add-to-fav {
    position: absolute;
    color: #1e90ff;
    top: 6px;
    left: 88%;
    font-size: 20px;
  }

  .card-body {
    h6 {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 768px) {
  .products-image {
    max-height: 50% !important;
  }
  .product-card {
    .card {
      width: 100%;
      height: 60vh !important;
    }
  }
}

.card:hover {
  // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 0 1px 2px #ffffff,
  //   0 0 1px 1px #1e90ff, 0 0 2px 2px #2f4f4f;
  box-shadow: 0px 6px 6px 5px #0000001f;
}

.card-body:hover,
.card-title:hover,
.card-text:hover {
  box-shadow: none;
}

.fa-store {
  color: #6f9fc4;
}
