.App {
  background-color: #f5f8f9;
  min-height: 100vh;
  min-width: 100%;
  color: #000000;
}

h1,
h2,
h3,
h4,
h5 {
  color: #061d33;
}

.btn-primary {
  background-color: #1e90ff !important;
  border: 1px solid #1e90ff !important;
  color: #ffffff !important;
}
.btn-primary:hover {
  background-color: #0080ff !important;
  border: 1px solid #0080ff !important;
  color: #ffffff !important;
}

.btn-outline-primary {
  /* background-color: #1E90FF !important; */
  border: 1px solid #1e90ff !important;
  color: #1e90ff !important;
}
.btn-outline-primary:hover {
  color: #ffffff !important;
}
/* .container {
  max-width: 100% !important;
} */
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 90% !important;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 95% !important;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 95% !important;
  }
}

@media (max-width: 767px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 98% !important;
  }
}
