.cart-detail-topbar-container {
  .cart-detail-topbar-Back {
    // grid-area: left;
    cursor: pointer;
    padding-left: 0;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .cart-detail-topbar-title {
    font-size: 16px;
    font-size: larger;
  }
}
.cart-detail-topbar {
  padding: 24px 0;
}
.bg-lights {
  background-color: #f5f8f9 !important;
}

@media screen and (max-width: 425px) {
  .cart-detail-topbar-welcome {
    display: none;
  }
}
