.account-informataion {
  background-color: #ffffff;
  padding: 32px 12px 18px;
  border-radius: 12px;
  margin-bottom: 60px;
  input {
    background-color: #ffffff !important;
  }
  .account-profile-button button {
    margin-left: 0 !important;
  }
}
