.no-notification {
  background-color: #ffffff;
  padding: 32px 12px 18px;
  border-radius: 12px;
  margin-bottom: 60px;
  text-align: center;
  .no-notification-img img {
    width: 280px;
    height: 273px;
  }
}
