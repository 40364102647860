.main-jumbotron {
  background-color: #ffffff;
  border-radius: 90px;
  .jumbotron {
    width: 90%;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 3em 12px;
    .jumbotron-texts {
      p {
        margin: 24px 0;
        padding: 8px 0;
        text-align: justify;
      }
      button {
        margin: 0 !important;
      }
    }
    .jumbotron-image img {
      border-radius: 12px;
      height: auto;
      width: 100%;
    }
  }
}

@media screen and (max-width: 425px) {
  .jumbotron {
    padding: 0 12px;
    .jumbotron-texts {
      margin-bottom: 12px;
    }
  }
}
